import { Route } from '@angular/router';

export const PAGES_ROUTES: Route[] = [
    {
        path: 'megarelease',
        title: 'МегаРелиз',
        data: {
            isNav: true,
            icon: 'm-article',
            navTitle: 'МегаРелиз',
            permissionId: 'megarelease',
        },
        loadComponent: () =>
            import(
                '@maPages/megarelease/megarelease-list/megarelease-list.component'
            ).then((m) => m.MegareleaseListComponent),
    },
    {
        path: 'logout',
        title: 'Выход',
        data: {
            isNav: true,
            icon: 'exit',
            navTitle: 'Выйти',
            permissionId: 'everyone',
        },
        loadComponent: () =>
            import('@maPages/logout/logout.component').then(
                (m) => m.LogoutComponent
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/megarelease',
    },
];
